.logo {
    max-width: 450px;
    position: fixed;
    top: 50vh;
    left: 50%;
    z-index: -1;
    opacity: 0.05;
    transform: translate(-50%, -50%);

    img {
        max-width: 100%;
        height: auto;
    }
}
