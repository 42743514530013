.alert {
    width: 75vw;
    max-width: 600px;
    min-height: 40px;
    padding: var(--gap) calc(var(--gap) + 40px) var(--gap) var(--gap);
    position: fixed;
    top: 2vw;
    right: 5vw;
    z-index: var(--zindex-modal);
    overflow: hidden;
    background: var(--alert-background, var(--color-white-darken-1));
    color: var(--alert-color, currentColor);
    line-height: 1.2;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: var(--border-radius);
}

.success {
    --alert-background: var(--color-green-lighten-1);
}

.info {
    --alert-background: var(--color-blue-lighten-1);
    --alert-color: var(--color-white);
}

.warning {
    --alert-background: var(--color-yellow-lighten-1);
}

.danger {
    --alert-background: var(--color-red-lighten-1);
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: calc(var(--zindex-modal) - 1);
    background: rgba(0, 0, 0, 0.25);
}

.button {
    --button-background: rgba(255, 255, 255, 0.25);
    --button-hover-background: rgba(255, 255, 255, 0.4);
    --button-color: var(--color-gray);
    --button-hover-color: var(--color-gray-darken-2);
    width: 40px;
    min-width: 0;
    height: 40px;
    min-height: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    color: var(--color-black);
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    border-radius: 0 var(--border-radius) calc(var(--border-radius) / 2) 0;

    &:focus,
    &:hover {
        box-shadow: none;
    }
}

.progress {
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.33);
}
