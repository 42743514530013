.button {
    min-width: 120px;
    min-height: 48px;
    padding: var(--gap) calc(2 * var(--gap));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--button-background, var(--color-gray-lighten-1));
    color: var(--button-color, var(--color-white));
    font-size: 0.95rem;
    font-weight: 700;
    transition-property: background-color, color, box-shadow;
    transition-duration: var(--transition-out);
    transition-timing-function: var(--transition-timing);
    border-radius: var(--border-radius);

    &:focus {
        box-shadow: 0 0 0 4px var(--button-outline-color, var(--color-gray-lighten-3));
    }

    &:not([disabled]):focus,
    &:not([disabled]):hover {
        background-color: var(--button-hover-background, var(--color-gray));
        color: var(--button-hover-color, var(--color-white-darken-1));
        transition-duration: var(--transition-in);
    }

    &:disabled {
        --button-color: var(--color-white-darken-1);
        opacity: 0.5;
        pointer-events: none;
    }
}

.success {
    --button-background: var(--color-green);
    --button-outline-color: var(--color-green-lighten-3);
    --button-hover-background: var(--color-green-darken-1);
}

.info {
    --button-background: var(--color-blue);
    --button-outline-color: var(--color-blue-lighten-3);
    --button-hover-background: var(--color-blue-darken-1);
}

.warning {
    --button-background: var(--color-yellow-darken-1);
    --button-outline-color: var(--color-yellow-lighten-2);
    --button-hover-background: var(--color-yellow-darken-2);
}

.danger {
    --button-background: var(--color-red);
    --button-outline-color: var(--color-red-lighten-3);
    --button-hover-background: var(--color-red-darken-1);
}
