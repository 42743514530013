.main {
    min-height: 100vh;
    display: grid;
    filter: blur(0);

    @media (--min-xs) {
        grid-template: minmax(calc(60px + (2 * var(--gap))), max-content) auto auto max-content / auto;
        grid-template-areas: 'header'
            'content'
            'aside'
            'footer';
    }

    @media (--min-md) {
        grid-template: 12vh auto auto max-content / auto;
        grid-template-areas: 'header'
            'content'
            'aside'
            'footer';
    }

    @media (--min-xl) {
        grid-template: 12vh minmax(min-content, 25vh) auto max-content / 25vw auto;
        grid-template-areas: 'header header'
            'nav content'
            'aside content'
            'footer footer';
    }

    @media (--min-xxl) {
        grid-template-columns: 20vw auto;
    }
}

.blur {
    filter: blur(4px);
    transition: filter var(--transition-in) linear;
}
