.aside {
    padding: calc(2 * var(--gap));
    display: grid;
    grid-area: aside;
    align-items: flex-end;

    svg {
        max-width: 240px;
    }

    @media (--max-xl) {
        max-width: 40%;
        margin: 0 auto;
    }

    @media (--max-sm) {
        max-width: 60%;
    }

    @media (--min-xl) {
        align-items: flex-start;
    }
}
